(function () {'use strict';}());
angular.module('app').service('AppService', function($http, $window)
{
	var trace = angular.trace;
	var self = {};

	self.login = function(email,password) {
		return $http.post("php/services/AppService.php",{request: "login", email: email, password: password}).success(onLogin);
	};

	self.logout = function() {
		return $http.post("php/services/AppService.php",{request: "logout"}).success(onLogout);
	};

	self.updatePassword = function(existing,password) {
		return $http.post("php/services/AppService.php",{request: "passwordChange", existing: existing, password: password});
	};

	self.saveUserInfo = function(info) {
		return $http.post("php/services/AppService.php",{request: "saveUserInfo", info: info});
	};

	self.createPasswordToken = function(email) {
		return $http.post("php/services/AppService.php",{request: "createPasswordToken", email: email});
	};

	self.resetPasswordAndLogin = function(password, token) {
		return $http.post("php/services/AppService.php",{request: "resetPasswordAndLogin", password: password, token: token});
	};

	self.submitReadinessResponses = function(responses) {
		return $http.post("php/services/AppService.php",{request: "submitReadinessResponses", responses: responses});
	};

	self.startModule = function(id,status) {
		trace(id,status);
		return $http.post("php/services/AppService.php",{request: "startModule", mid: id, uid: __UINFO__.uid, status: status});
	};


	function onLogin(result) {
	}

	function onLogout() {
		$window.location.reload();
	}

	return self;
});