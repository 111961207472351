(function () {'use strict';}());

angular.module('app')
  .controller('ResourceController', function ($scope,$timeout,$window,ResourceService,AppService) {
  	var trace = angular.trace;
  	$scope.resources = [];
  	$scope.filters = [];
    $scope.currentResource = null;
    $scope.modalClasses = [];
    $scope.uid = __USER__;
    $scope.boilerplate = __BOILERPLATE__;

  	$scope.showResource = function(resource){
      ResourceService.sendEvent($scope.uid,"view",resource.id);
      if(resource.type === "document" || resource.type === "link"){
        $window.open(resource.url);
        return;
      }
      $scope.currentResource = resource;  
      $timeout(renderModal,0);   
      $timeout(renderMedia,1000); 
  	};

    $scope.close = function(){
      $scope.modalClasses = [$scope.currentResource.type];
      $timeout(hideModal,500);
    };

    $scope.gotoHomePage = function(){
      $window.location.href = "/index.php";
    };

    $scope.logout = function(){
      __AUTHENTICATED__ = false;
      AppService.logout().then($scope.gotoHomePage);
    };

    function renderModal(){
      $scope.modalClasses = ["rendered",$scope.currentResource.type];
    }
    function renderMedia(){
      if($scope.currentResource) $scope.currentURL = $scope.currentResource.url;
    }
    function hideModal(){
      $scope.currentResource = $scope.currentURL = null;
      $scope.modalClasses = [];
    }

    function initialize(){
    	ResourceService.fetch().then(onResourcesFetched);
    }

    function onResourcesFetched(){
    	$scope.resources = ResourceService.resources;
    	$scope.filters = ResourceService.filters;
    }

    initialize();
  });
