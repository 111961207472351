angular.module('app').filter('modules', function() {
    return function(listing,type) {
    	if(!listing) return listing;
        var trace = angular.trace;
        var results = [];

        for(var i = 0, count = listing.length; i < count; i++) {
            var item = listing[i];
            if(item.type === type) results.push(item);
        }
    	return results;
    };
});