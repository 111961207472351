angular.module('app').filter('resources', function() {
    return function(listing,selected) {
    	if(!listing || !selected) return listing;
        var trace = angular.trace;
    	var results = [];
    	var i,count,item,j,scount,filters,filter;
    	var active = [];

    	for(i = 0, count = selected.length; i < count; i++){
    		item = selected[i];
    		if(item.selected && active.indexOf(item.id) === -1) active.push(item.id);
    	}

    	if(!active.length) return listing;


    	for(i = 0, count = listing.length; i < count; i++){
    		item = listing[i];
    		filters = item.filters;
    		for(j = 0, scount = filters.length; j < scount; j++){
    			filter = filters[j];
    			if(active.indexOf(filter) !== -1){
    				results.push(item);
    				break;
    			}
    		}
    	}
        
    	return results;
    };
});