(function () {'use strict';}());
angular.module("app").directive('modalLogin', function($document,$timeout, $window) {
    return {
      restrict: 'A',
      templateUrl: 'views/modal-login.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.tempInfo = {email: null, password: null};
        $scope.disabled = false;
        $scope.mode = $scope.token ? "reset" : "login";
        $scope.status = null;
        $scope.message = null;

        $scope.setCurrentMode = function(value) {
          $scope.status = null;
          $scope.message = null;
          $scope.mode = value;
        };

        $scope.attemptLogin = function() {
          $scope.disabled = true;
          $scope.status = null;
          $scope.message = null;
        	$scope.login($scope.tempInfo).then(onLoginAttempt);
        };

        $scope.lostPassword = function() {
          $scope.status = null;
          $scope.message = null;
          $scope.disabled = true;
          $scope.submitted = true;
          $scope.createPasswordToken($scope.tempInfo.email).then(onCreatePasswordToken);
        };

        $scope.resetPassword = function() {
          $scope.status = null;
          $scope.message = null;

          if(!$scope.tempInfo.password || !$scope.tempInfo.passwordConfirm) {
            $scope.message = $scope.boilerplate.txtPPCompleteAllFields;
            $scope.status = "error";
            return;
          }

          if($scope.tempInfo.password.replace(/\s+/gim,"").length < 8) {
            $scope.message = $scope.boilerplate.txtPPTooShort;
            $scope.status = "error";
            return;
          }

          if($scope.tempInfo.password !== $scope.tempInfo.passwordConfirm) {
            $scope.message = $scope.boilerplate.txtPPNoMatch;
            $scope.status = "error";
            return;
          }

          $scope.resetPasswordAndLogin($scope.tempInfo.password).then(onResetAndLogin);

        };

        function onLoginAttempt(result) {
          var info = result.data;
          if(info.authenticated === true) {
            $scope.tempInfo = {email: null, password: null};
            $scope.uinfo.uid = info.uid;
            $scope.uinfo.fname = info.fname;
            $scope.uinfo.lname = info.lname;
            $scope.uinfo.email = info.email;
            $scope.uinfo.role = info.role;
            $scope.uinfo.role2 = info.role2;
            $window.location.reload();

            $scope.userAuthenticated();
          } else {
            $scope.message = info.message;
            $scope.disabled = false;
          }
        }

        function onCreatePasswordToken(result) {
          var info = result.data;
          $scope.status = info.status;
          $scope.message = info.message;

          if(info.status === "error") $scope.submitted = false;
          trace(info);
        }

        function onResetAndLogin(result) {
          var info = result.data;
          if(info.status === "error") {
            $scope.status = info.status;
            $scope.message = info.message;
            return;
          }
          $window.location.href = window.location.origin;
        }
      }  
    };
  });