(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,$timeout,AppService) {
  	var trace = angular.trace;
  	var pageTimeout, subpageTimeout;
  	$scope.TRANSITION_SPEED = 500;

  	$scope.PAGE_LOGIN = 1;
    $scope.PAGE_GETTINGSTARTED = 2;
		$scope.PAGE_TIMELINE = 3;
    $scope.PAGE_INTERNAL = 4;
    $scope.PAGE_READINESS = 5;
    /** NEXT PAGE INSERTION MARKER INDEX: 6 **/
    /** NOTE DO NOT CHANGE LINE ABOVE MANUALLY **/


    $scope.SUBPAGE_USESITE = 1;
		$scope.SUBPAGE_COREMODULES = 2;
		$scope.SUBPAGE_ROLEMODULES = 3;
		$scope.SUBPAGE_RESOURCES = 4;
		$scope.SUBPAGE_DISCUSSION = 5;
		$scope.SUBPAGE_CLASSES = 6;
		$scope.SUBPAGE_PROFILE = 7;
		$scope.SUBPAGE_COMMUNITY = 8;
		/** NEXT SUBPAGE INSERTION MARKER INDEX: 9 **/
    /** NOTE DO NOT CHANGE LINE ABOVE MANUALLY **/

    $scope.MODAL_CONTACT = 1;
    $scope.MODAL_LOGIN = 2;
		
  	$scope.containerClasses = {changePage: false, changeSubpage: false, showMobileMenu: false};
  	$scope.requestedPage = null;
  	$scope.requestedSubPage = null;
    $scope.currentModal = null;
    $scope.currentSubPage = null;
    $scope.authenticated = __AUTHENTICATED__;
  	$scope.boilerplate = __BOILERPLATE__;
    $scope.modules = __MODULES__;
    $scope.uinfo = __UINFO__;
    $scope.token = __TOKEN__;
    $scope.readiness = __READINESS__;

    $scope.login = function(info) {
      return AppService.login(info.email, info.password);
    };

    $scope.logout = function() {
      return AppService.logout();
    };

    $scope.updatePassword = function(existing,password) {
      return AppService.updatePassword(existing,password);
    };

    $scope.saveUserInfo = function(info) {
      return AppService.saveUserInfo(info);
    };

    $scope.createPasswordToken = function(email) {
      return AppService.createPasswordToken(email);
    };

    $scope.resetPasswordAndLogin = function(password) {
      return AppService.resetPasswordAndLogin(password, $scope.token);
    };  

    $scope.submitReadinessResponses = function(responses) {
        return AppService.submitReadinessResponses(responses);
    };

    $scope.userAuthenticated = function() {
      $scope.authenticated = true;
      $scope.setCurrentModal(null);
      $scope.goHome();
    };

    $scope.goHome = function() {
      if(!$scope.authenticated) $scope.setCurrentPage($scope.PAGE_LOGIN);
      else {
        $scope.setCurrentPage($scope.PAGE_INTERNAL);
        $scope.setCurrentSubPage($scope.SUBPAGE_USESITE);
      }
    };

    $scope.showMobileMenu = function() {
      $scope.containerClasses.showMobileMenu = !$scope.containerClasses.showMobileMenu;
    };



  	$scope.setCurrentPage = function(value) {
  		if($scope.containerClasses.changePage === true) return false;
      if($scope.currentPage === value) return false;
      window.scrollTo(0,0);
  		$scope.requestedPage = value;
  		$scope.containerClasses.changePage = true;
  		pageTimeout = $timeout(executePageChange,$scope.TRANSITION_SPEED);
  	};

  	function executePageChange() {
  		$scope.currentPage = $scope.requestedPage;
  		$scope.requestedPage = null;
  		$timeout(clearPageClasses,10);
  	}

  	function clearPageClasses() {
  		$scope.containerClasses.changePage = false;
      $scope.containerClasses.changeSubPage = false;
  	}

  	$scope.setCurrentSubPage = function(value) {
      $scope.containerClasses.showMobileMenu = false;
      if($scope.containerClasses.changeSubPage === true) return false;
      $scope.requestedSubPage = value;
      $scope.containerClasses.changeSubPage = true;
      subpageTimeout = $timeout(executeSubPageChange,$scope.TRANSITION_SPEED);
  	};

    function executeSubPageChange() {
      $scope.currentSubPage = $scope.requestedSubPage;
      $scope.requestedSubPage = null;
      $timeout(clearPageClasses,10);
    }

    $scope.setCurrentModal = function(value) {
      if(!value) return closeCurrentModal();

      $scope.currentModal = value;
      $timeout(renderModal,10);
    };

    function renderModal() {
      $scope.containerClasses.showModal = true;
    }

    function closeCurrentModal() {
      $scope.containerClasses.showModal = false;
      $timeout(clearModal, $scope.TRANSITION_SPEED);
    }

    function clearModal() {
      $scope.currentModal = null;
    }

  	$scope.getCurrentPageName = function(){
    	for(var prop in $scope){
    		if(!prop.match(/^PAGE_/)) continue;
    		if($scope[prop] !== $scope.currentPage) continue;

    		var page = prop.replace(/^PAGE_/gim,"").toLowerCase();
    		return page;
    	}
    	return null;
    };

    $scope.getCurrentSubPageName = function(){
      for(var prop in $scope){
        if(!prop.match(/^SUBPAGE_/)) continue;
        if($scope[prop] !== $scope.currentSubPage) continue;

        var page = prop.replace(/^SUBPAGE_/gim,"").toLowerCase();
        return page;
      }
      return null;
    };

    $scope.getPageClass = function(page){
    	if(page < $scope.currentPage) return "left";
    	if(page > $scope.currentPage) return "right";
    	return "center";
    };

    $scope.startModule = function(module) {
      if(module.status === "complete") return;
      module.status = "started";
      trace(module);
      AppService.startModule(module.id, module.status);
    };


    function initalize(){
    	$timeout(function(){
    		$scope.setCurrentPage(__AUTHENTICATED__ ? $scope.PAGE_INTERNAL : $scope.PAGE_LOGIN);
        if(__AUTHENTICATED__) {
          var requestedState = window.GET.state;
          switch(requestedState) {
            case "dashboard":
              $scope.currentSubPage = $scope.SUBPAGE_COREMODULES;
            break;
            case "role-dashboard":
              $scope.currentSubPage = $scope.SUBPAGE_ROLEMODULES;
            break;
            default:
              $scope.currentSubPage = $scope.SUBPAGE_USESITE;
          }
        }
        if($scope.token) $scope.setCurrentModal($scope.MODAL_LOGIN);
    	},250);
    }

    $(document).ready(initalize);


  });
