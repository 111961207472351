(function () {'use strict';}());
angular.module("app").directive('profileView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/profile.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.roles = __ROLES__;
        $scope.tempInfo = angular.copy($scope.uinfo);
        $scope.tempPass = {};
        $scope.infoStatus = null;
        $scope.infoMessage = null;
        $scope.passwordMessage = null;
        $scope.passwordStatus = null;

        var i, count, role;

        for(i = 0, count = $scope.roles.length; i < count; i++) {
        	role = $scope.roles[i];
        	if(role.label === $scope.tempInfo.role) {
        		$scope.tempInfo.role = role;
        		break;
        	}
        }

        for(i = 0, count = $scope.roles.length; i < count; i++) {
            role = $scope.roles[i];
            if(role.label === $scope.tempInfo.role2) {
                $scope.tempInfo.role2 = role;
                break;
            }
        }



        $scope.saveBasicInfo = function() {
        	var info = angular.copy($scope.tempInfo);
        	info.role = info.role.label;
            info.role2 = info.role2.label;
        	$scope.infoStatus = null;
        	$scope.infoMessage = null;
        	$scope.saveUserInfo(info).then(onBasicInfoSaved);
        };

        $scope.savePassword = function() {
        	$scope.passwordMessage = null;
        	$scope.passwordStatus = null;

        	if(!$scope.tempPass.password || !$scope.tempPass.verify || !$scope.tempPass.confirm) {
        		$scope.passwordMessage = $scope.boilerplate.txtPPCompleteAllFields;
        		$scope.passwordStatus = "error";
        		return;
        	}

        	if($scope.tempPass.password.replace(/\s+/gim,"").length < 8) {
        		$scope.passwordMessage = $scope.boilerplate.txtPPTooShort;
        		$scope.passwordStatus = "error";
        		return;
        	}

        	if($scope.tempPass.password !== $scope.tempPass.confirm) {
        		$scope.passwordMessage = $scope.boilerplate.txtPPNoMatch;
        		$scope.passwordStatus = "error";
        		return;
        	}

        	$scope.submitting = true;
        	$scope.updatePassword($scope.tempPass.verify, $scope.tempPass.password).then(onUpdateComplete);
        };

        function onUpdateComplete(result) {
        	$scope.passwordStatus = result.data.status;
        	$scope.passwordMessage =result.data.message;
        	$scope.tempPass = {};
        }

        function onBasicInfoSaved(result) {
        	$scope.infoStatus = result.data.status;
        	$scope.infoMessage =result.data.message;

        	if($scope.infoStatus !== "success") return;
        	$scope.uinfo.fname = $scope.tempInfo.fname;
        	$scope.uinfo.lname = $scope.tempInfo.lname;
        	$scope.uinfo.email = $scope.tempInfo.email;
        	$scope.uinfo.role = $scope.tempInfo.role.label;
            $scope.uinfo.role2 = $scope.tempInfo.role2.label;

        	trace("success",$scope.uinfo,$scope.tempInfo);
        }


      }  
    };
  });