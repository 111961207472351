(function () {'use strict';}());
angular.module('app').service('ResourceService', function($http,$window)
{
	var trace = angular.trace;
	var self = {};

	self.fetch = function(){
		return $http.post("php/services/ResourceService.php",{request:"fetch"}).success(onResourcesFetched).error(onConnectionError);
	};

	self.sendEvent = function(user,type,resource) {
		return $http.post("php/services/ResourceService.php",{request:"event",user:user,type:type,resource:resource});
	};

	function onResourcesFetched(data){
		self.resources = data.resources;
		self.filters = data.filters;
		self.userFilters = data.userFilters;
		synthesize();
	}

	function synthesize(){
		var i,count;

		for(i = 0, count = self.filters.length; i < count; i++){
			var filter = self.filters[i];
			if(self.userFilters.indexOf(filter.id) !== -1) filter.selected = true;
			else filter.selected = false;
		}

		//TEMP 
	}

	function onConnectionError(){
		$window.alert("There was an error communicating with the server. Please refresh your browser and try again.");
	}

	return self;

});