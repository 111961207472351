(function () {'use strict';}());
angular.module("app").directive('usesiteView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/usesite.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;

        $timeout(function(){
        	$scope.iframe = $scope.boilerplate.ytUseSite;
        },$scope.TRANSITION_SPEED);
      }  
    };
  });