(function () {'use strict';}());
angular.module("app").directive('readinessView', function($document,$timeout) {
    return {
      restrict: 'A',
      templateUrl: 'views/readiness.html',

      link:function($scope,element,attr)
      {
        var trace = angular.trace;
        $scope.questions = {};
        $scope.submitting = false;
        $scope.showReadinessIndex = true;

        $scope.submit = function() {
        	$scope.submitting = true;
        	$timeout(submitResponses, $scope.TRANSITION_SPEED);
        };

        function submitResponses() {
        	var responses = serializeResponses();
        	$scope.submitReadinessResponses(responses).then(onResponsesSubmitted);
        }

        function onResponsesSubmitted(result) {
        	$scope.submitting = false;
        	$scope.showReadinessIndex = false;
            $scope.guid = result.data.guid;
        }

        function serializeResponses() {
        	var responses = [];

        	for(var qtid in $scope.questions) {
        		var qid = parseInt(qtid.replace(/question/gim,""));
        		var question = getQuestionObject(qid);
        		var obj = {id: qid, type: question.questionType == "input" || question.questionType == "textarea" ? "text" : "choice"};
        		var response = $scope.questions[qtid];
        		
        		if(obj.type === "text") {
        			obj.response = response;
        		} else {
        			obj.response = buildChoiceResponse(question, response);
        		}
        		responses.push(obj);
        	}

        	return responses;
        }

        function buildChoiceResponse(question, response) {
        	if(question.questionType === "radio") return [response];
        	var responses = [];

        	for(var oid in response) {
        		var option = response[oid];
        		if(!option) continue;
        		responses.push(option);
        	}

        	return responses;
        }


        function getQuestionObject(id) {
        	for(var i = 0, count = $scope.readiness.length; i < count; i ++) {
        		var question = $scope.readiness[i];
        		if(question.questionId === id) return question;
        	}
        	return null;
        }
      }  
    };
  });